/**
 * src/css/easy_contact.css
 *
 * @author Johannes Braun
 * @package easy-contact
 */

dialog.easy-contact-dialog {

	&::backdrop {
		background-color: rgba(#262e46, 1); //rgba(30, 60, 90, 0.9);
	}

	--border-width: 1px;
	--bg-color: #cd5958;
	--fg-color: #fff;
	--shadow-color: #262e46;

	background-color: var(--bg-color);
	border: var(--border-width) solid var(--fg-color);
	border-radius: 0; //1.5rem;
	// box-shadow: 1.5rem 1.5rem 0 var(--shadow-color);

	min-width: 44rem;

	position: fixed;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	transition: 250ms ease-out;
	opacity: 0;
	display: block;

	&[open] {
		top: 50%;
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}

	ul {
		display: flex;
		flex-direction: column;
		align-items: center;
		> li + li {
			margin-top: 2rem;
		}
	}

	a {
		color: var(--fg-color);
		font-size: 20px;
		line-height: 1.35;
		text-decoration: none;
		border: var(--border-width) solid currentColor;
		display: inline-block;
		padding: 0.5rem 1rem;
		text-align: center;
		width: max-content;

		&:hover,
		&:focus {
			background-color: var(--fg-color);
			color: var(--bg-color);
		}
	}

	> div {
		padding: 2rem;
	}

	header {
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem;
		font-size: 20px;
		color: var(--fg-color);
		text-align: center;
		border-bottom: var(--border-width) solid currentColor;
		padding: 1.5rem 2rem;
		line-height: 1;

		button {
			margin-left: auto;
			width: 2rem;
			height: 2rem;
			// line-height: 2rem;
			text-align: center;
			border: var(--border-width) solid currentColor;
			color: var(--fg-color);
			background-color: transparent;
			padding: 0;
			font-size: 20px;
			font-weight: bold;
			cursor: pointer;
			&:hover {
				background-color: var(--fg-color);
				color: var(--bg-color);
				border: 0;
				transform: scale(1.25);
			}
		}
	}

}

