/**
 * src/css/easy_contact.css
 *
 * @author Johannes Braun
 * @package easy-contact
 */
dialog.easy-contact-dialog {
  --border-width: 1px;
  --bg-color: #cd5958;
  --fg-color: #fff;
  --shadow-color: #262e46;
  background-color: var(--bg-color);
  border: var(--border-width) solid var(--fg-color);
  border-radius: 0;
  min-width: 44rem;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 250ms ease-out;
  opacity: 0;
  display: block;
}

dialog.easy-contact-dialog::backdrop {
  background-color: #262e46;
}

dialog.easy-contact-dialog[open] {
  top: 50%;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

dialog.easy-contact-dialog ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

dialog.easy-contact-dialog ul > li + li {
  margin-top: 2rem;
}

dialog.easy-contact-dialog a {
  color: var(--fg-color);
  font-size: 20px;
  line-height: 1.35;
  text-decoration: none;
  border: var(--border-width) solid currentColor;
  display: inline-block;
  padding: 0.5rem 1rem;
  text-align: center;
  width: max-content;
}

dialog.easy-contact-dialog a:hover, dialog.easy-contact-dialog a:focus {
  background-color: var(--fg-color);
  color: var(--bg-color);
}

dialog.easy-contact-dialog > div {
  padding: 2rem;
}

dialog.easy-contact-dialog header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 20px;
  color: var(--fg-color);
  text-align: center;
  border-bottom: var(--border-width) solid currentColor;
  padding: 1.5rem 2rem;
  line-height: 1;
}

dialog.easy-contact-dialog header button {
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  text-align: center;
  border: var(--border-width) solid currentColor;
  color: var(--fg-color);
  background-color: transparent;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

dialog.easy-contact-dialog header button:hover {
  background-color: var(--fg-color);
  color: var(--bg-color);
  border: 0;
  transform: scale(1.25);
}
